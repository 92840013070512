/* Impact.css */
.ImpactContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  padding: 2%;
  overflow-x: auto; 
  width: 100%;
  scrollbar-width: none; 
}

.ImpactContainer::-webkit-scrollbar {
  display: none; 
}

.sdg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
}

.sdg img.impact-image  {
  width: 15%;
  margin: 10px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.sdg img.impact-image1 {
  height: 20vh;
  margin: 10px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.sdg img.impact-image2 {
  /* min-width: 145px; */
  height: 162px;
  width: 162px;
  /* width: 28%; */
  margin: 10px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.sdg img.impact-image1:hover,
.sdg img.impact-image2:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.sgds {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 80vw;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.sdg img.impact-image {
  animation: fadeInUp 1s ease-out;
}

.impactcard {
  display: flex;
  overflow: hidden;
  padding: 2%;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 30px;
  justify-content: center;
}

.impc {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 200px;
  border-radius: 15px;
  background-color: #45e6952b;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  justify-content: space-between;
  min-width: 250px;
  padding-top: 10px;
}

.impactf {
  display: flex;
  flex-direction: row;
  padding: 5px;
}

.impactfimg {
  height: 10vh;
}

.impc:hover {
  transform: scale(1.05);
}

.impact1 {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.impact-image {
  width: 98%;
  border-radius: 10px;
  object-fit: cover;
  border: 3px solid #13a229;
}

.impact-image_ {
  width: 15%;
  border-radius: 10px;
  object-fit: cover;
  min-width: 170px;
}

.impact-description {
  text-align: center;
  color: rgb(0, 0, 0);
}

.impact-description h3 {
  margin: 10px 0;
  font-size: 0.8rem;
}

.impact-description p {
  margin: 0;
  font-size: 1rem;
}

@media (max-width: 450px) {
  .impc {
    margin: 10px;
    width: 300px;
  }
  .impc:hover {
    transform: none;
  }
}

@media (min-width: 450px) and (max-width: 900px) {
  .impc {
    width: 30%;
  }
}

/* Importing fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");
@import url("https://db.onlinewebfonts.com/c/1c6b6cddab6609324d9016562358e0cd?family=Biome+W04+Regular");

/* Apply to the body */
body {
  margin: 0;
  font-family: 'Biome W04 Regular', 'Inter', 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5FFFF;
}

/* Styling for code blocks */
code {
  font-family: 'Biome W04 Regular','Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
}

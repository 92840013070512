.typebox {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}
/* ul{
    list-style-type:  disk !important;
} */
.custom-container {
    display: flex;
    flex-direction: column;
    font-size: 1.125rem; /* Equivalent to text-lg */ /* Equivalent to text-gray-700 */
  }

.profile-card {
  border: 2px solid #1068908f;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 15px;
    padding: 15px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff00;
  margin: 10px;
  font-size: 0.9em; /* Slightly smaller font size for the whole card */
}

.profile-picture img {
  width: 200px; /* Smaller image size */
  /* margin-right: 15px; */
}

.profile-details h2 {
  margin: 0;
  color: #000;
  font-size: 1.2em; /* Slightly smaller font size for name */
  text-align: center;
  padding-top: 8px !important;

}

.profile-details h3 {
  margin: 5px 0;
  color: #0073e6;
  font-size: 1em; /* Slightly smaller font size for title */
  text-align: center;

}

.profile-role h4 {
  /* margin: 10px 0 5px 0; */
  color: #000;
  font-size: 0.9em; /* Slightly smaller font size for role team */
  text-align: center;
  letter-spacing: normal;
  margin: 0;
}

.profile-role ul {
  list-style-type: disc;
  padding-left: 20px;
}

.profile-role li {
  margin: 5px 0;
  font-size: 0.9em; /* Slightly smaller font size for role items */
}

.profile-details p {
  margin: 0px 0 0 0;
  color: #000;
  font-size: 0.9em; /* Slightly smaller font size for degree */
  text-align: center;

}

@media (max-width: 550px) {
.profile-picture img {
  width: 250px !important; /* Smaller image size for mobile */
}
}

:root {
  --primary: #106790;
  --dark-primary: #106790;
  --light-primary: #10689042;
  --text: #FFFFFF;
  --primary-text: #212121;
  --secondary-text: #757575;
  --accent: #106790;
}

h1 {
  font-size: 200%;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 400;
  text-align: center;
}
.timeline-content h2{
  font-size: 1.5rem;
}
p {
  text-align: center;
}

.timeline {
  position: relative;
  margin: 0 auto;
  padding: 50px 0;
  width: 90%;
}

.timeline::before {
  content: '';
  background: var(--light-primary);
  width: 5px;
  height: 90%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.timeline-item {
  width: 100%;
  margin-bottom: 70px;
  overflow: hidden;
}

.timeline-item:nth-child(even) .timeline-content {
  float: right;
  /* padding: 40px 30px 10px 30px; */
}

.timeline-item:nth-child(even) .timeline-content .date {
  right: auto;
  left: 0;
}

.timeline-item:nth-child(even) .timeline-content::after {
  content: '';
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
  top: 30px;
  left: -15px;
  border-width: 10px 15px 10px 0;
  border-color: transparent #f5f5f5 transparent transparent;
}

.timeline-content {
  position: relative;
  width: 38%;
  /* padding: 10px 30px; */
  padding-bottom: 3%;
  border-radius: 4px;
  background: #89c7e958;
  box-shadow: 0 20px 25px -15px rgba(0, 0, 0, .3);
  float: left;
}

.timeline-content::after {
  content: '';
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
  top: 30px;
  right: -15px;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #f5f5f5;
}

.timeline-img {
  width: 30px;
  height: 30px;
  background: var(--primary);
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-top: 25px;
  margin-left: -15px;
}

.timeline-content a {
  background: var(--primary);
  color: var(--text);
  padding: 8px 20px;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 10px;
  display: inline-block;
  border-radius: 2px;
  box-shadow: 0 1px 3px -1px rgba(0, 0, 0, .6);
}

.timeline-content a:hover,
.timeline-content a:active,
.timeline-content a:focus {
  background: var(--dark-primary);
  color: var(--text);
  text-decoration: none;
}

.timeline-img-header {
  height: 200px;
  position: relative;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.timeline-img-header h2 {
  color: var(--text);
  position: absolute;
  bottom: 5px;
  left: 20px;
}

blockquote {
  margin-top: 30px;
  color: var(--secondary-text);
  border-left: 5px solid var(--primary);
  padding: 0 20px;
}

.date {
  background: var(--accent);
  display: inline-block;
  color: var(--text);
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .timeline::before {
    left: 20px;
  }

  .timeline-img {
    left: 20px;
  }

  .timeline-content {
    max-width: 100%;
    width: auto;
    margin-left: 40px;
    float: none;
  }

  .timeline-item:nth-child(even) .timeline-content {
    float: none;
  }

  .timeline-item:nth-child(odd) .timeline-content::after {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    left: -15px;
    border-width: 10px 15px 10px 0;
    border-color: transparent #f5f5f5 transparent transparent;
  }
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 250px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 20px;
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-content {
  padding: 20px;
  min-height: 180px;
  align-content: center;

}

.card-name {
  font-size: 1.2em;
  margin: 0.3em 0;
}

.card-title {
  font-size: .9em;
  color: #666;
  margin: 0.3em 0;
}

.card-description {
  font-size: .8em;
  color: #888;
}

/* Form.css */
/* .css-svzhua-MuiPaper-root-MuiAlert-root{
    background-color: rgb(208 240 208);
} */
.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100%;
    background-color: #f5ffff;
    flex-direction: column;
    margin-top: 5%;

  }
  
  .form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
  }
  
  .form h2 {
    margin-bottom: 10px;
  }
  
  .form p {
    margin-bottom: 20px;
  }
  
  .form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .form label input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .form button {
    display: inline-block;
    width: 100%;
    padding: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form button:hover {
    background-color: #555;
  }
  
  @media (max-width: 600px) {
    .form {
      padding: 10px;
    }
  
    .form label input {
      padding: 8px;
    }
  
    .form button {
      padding: 8px;
    }
  }
  
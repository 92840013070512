/* 
.containerC {
    display: flex;
    flex-direction: row;
    align-items: center;  
    justify-content: center; 
  }
  
  .image-container {
    width: 40%;  
    margin-right: 1rem;
    background-image: url('../../public/careerImg/truckBlack.jpg');
    background-size: cover;
    background-position: center;
    height: 300px; 
    border-radius: 8px; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  }
  
  .image-container img {
    display: none;  
  }
@media (max-width: 768px) {
    .containerC {
      flex-direction: column; 
      align-items: center;
    }
  
    .image-container {
      width: 80%;
      margin-bottom: 1rem; 
      background-image: url('../../public/careerImg/truckQuote.jpg'); 
      height: 250px; 
    }
  
    .image-container img {
      display: none; 
    }
} */
.ContainerC{
    flex-direction: row;
}
.imageCCon2{
    display: none;
}
@media (max-width: 1000px){
    .imageCCon{
        display: none;
    }
    .ContainerC{
        flex-direction: column;
    }
    .imageCCon2{
        display: flex;
    }
    .headtextcareers{
      display: none;
    }

}
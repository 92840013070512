/* ElectricRefrigerationUnit.css */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.container {
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 5%;
  overflow: hidden;
}

.container.in-view {
  animation: fadeIn 1s ease-in;
}

.highlight {
  color: #0fa5ec;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin-left: 5%;
  margin-right: 5%;
}

.unit-label  {
  position: absolute;
  text-align: center;
  background-color: white;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.unit-label1 {
  position: absolute;
  text-align: center;
  background-color: white;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.unit-label.slide-in {
  animation: slideInFromLeft 1s ease-in-out;
}
.unit-label1.slide-in {
  animation: slideInFromRight 1s ease-in-out;
}
.unit-label p {
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  color:#106790;
}
.unit-label1 p {
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  color: #106790;
}

.condenser {
  top: 20px;
  left: 20px;
}

.control {
  bottom: 20px;
  left: 50px;
}

.evaporator {
  top: 20px;
  right: 20px;
}

.puf {
  bottom: 20px;
  right: 50px;
}

.main-image {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}

.main-image.fade-in {
  animation: fadeIn 1s ease-in;
}

.benefits {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-bottom: 5%;

}
.logosp{
  /* display: flex; */
  display:none ;
  justify-content: center;
  background-color: 106790;
  margin: 2px;
  border-radius: 15px;
}
.benefit-item {   
  background-color: #ffffff00;
  border: 2px solid #106790;
  border-radius: 5px;
  padding: 5px; /* Reduce padding */
  margin: 3px; /* Reduce margin */
  width: 30vh; /* Set specific width */
  height: 100px; /* Set specific height */
  text-align: center;
  
}
.benefit-item p{
  font-size: 15px;
}
.benefits.fade-in {
  animation: fadeIn 2s ease-in;
}

.benefit-item.slide-in-bottom {
  animation: slideInFromBottom 1s ease-in-out;
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hello h4{
  text-align: justify;
  font-size: 1rem;
}

.logop{
  /* padding-top: 8%; */
  display: flex;
  justify-content: center;

}

















.spinner {
  display: flex;
    height: 50vh;
    align-items: center;
    justify-content: center;
}

.spinner-wrap {
  position: relative;
}

.spinner-item {
  border: 1px solid #106790;
  background-color: #106790;
  border-radius: 50%;
  height: 200px;
  width: 200px;
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: zoom 2s linear 0.75s infinite;
}

.spinner-item--2 {
  animation-delay: 1.25s;
}

.spinner-item--3 {
  animation-delay: 1.75s;
}

.spinner-text {
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #000;
  font-weight: 800;
}

@keyframes zoom {
  0% {
    transform: translate(-50%, -50%) scale(0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

.ovtcontainer{
  position: relative;
}

.overlay-text {
  /* position: absolute; */
  left: 100%; /* Start off-screen to the right */
  top: 50%;
  transform: translate(0%, -50%);
  font-size: 1.5em;
  color: #000;
  opacity: 0;
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 20px;
  

  margin-left: 60%;
  /* width: fit-content; */
  /* animation: fadeInRight 2s forwards; */
}
.overlay-text .hello{
  font-size: 20px;
  margin: 0;

}
.hello .h4{
  font-weight: 400;
  margin: 0;
  
}
.overlay-text.fade-in {
  animation: fadeInRight 1s ease forwards;
}

.hello {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 300px;
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 3s;
}

/* .hello :hover {
  font-size: 21px;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInRight {
  0% {
    left: 60%;
    opacity: 0;
  }
  100% {
    left: 50%;
    opacity: 1;
  }
}









.containerp{
  padding-bottom: 10%;
}

.containerp h1{
  margin-top: 0;
  font-size: 40px;
}

.step-wrapper {
  margin: 10%;
  /* margin-bottom: 200px; */
  position: relative;
  margin-top: 5%;
  margin-bottom: 5%;

}

.linestep {
  text-align: center;
  max-width: 250px;
  position: absolute;
}
.linestep .num {
  color: #fff;
  border-radius: 100px;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  background: #452df8;
}
.linestep p {
  width: 100%;
  position: absolute;
  margin-top: 7px;
  font-weight: 500;
  line-height: normal;
  font-size: 18px;
}

.linestep p.top {
  bottom: 50px;
  left: -110px;
}
.linestep p.bottom {
  left: -110px;
}
.linestep p.left {
  width: 200px;
  right: 34px;
  top: -17px;
}
.linestep p.right {
  width: 200px;
  left: 34px;
  top: -17px;
}

.linestep {
  visibility: hidden;
}
.path {
  visibility: hidden;
}

.move-line .path {
  visibility: visible;
  stroke-dasharray: 3700;
  stroke-dashoffset: 3700;
  animation: moveline 4s linear forwards;
}
.move-line .linestep {
  animation-name: show-step-item;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}
.move-line .linestep1 {
  animation-delay: 0.4s;
}
.move-line .linestep2 {
  animation-delay: 0.8s;
}
.move-line .linestep3 {
  animation-delay: 1.2s;
}
.move-line .linestep4 {
  animation-delay: 1.6s;
}
.move-line .linestep5 {
  animation-delay: 2s;
}
.move-line .linestep6 {
  animation-delay: 2.4s;
}
.move-line .linestep7 {
  animation-delay: 2.8s;
}
.move-line .linestep8 {
  animation-delay: 3.2s;
}

@keyframes show-step-item {
  from {
    transform: scale(0.2);
    opacity: 0;
  }
  to {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes moveline {
  to {
    stroke-dashoffset: 0;
  }
}








@media (min-width: 450px) and (max-width: 900px) {
  .overlay-text{
    margin: 0;
    padding-left: 400px;
  }
  .main-image{
    width: 600px !important;
  }
}

@media (min-width: 0px) and (max-width: 450px) {
  .spinner-text{
    font-size: 15px;
    width: 30%;
  }
  .hello h4{
    font-size: 10px ;
    font-weight: 600 !important;
    overflow-wrap: break-word;
  }
  .overlay-text{
    transform: translate(-10%, -50%);
  }
  .spinner {
    height: 45vh;
}
.image-container{
  height: 50vh;
}
.main-image {
  width: 100%;
}
.image-container{
  margin: 0;
}
.unit-label{
  width: 30% !important;
  border-radius: 10px !important;
}
.unit-label1{
  width: 30% !important;
  border-radius: 10px !important;
}
.unit-label1 img{
  border-radius: 5px !important;
}
.unit-label img{
  border-radius: 5px !important;
}
.hello{
  min-width:auto;
}
.main-image{
  padding-top: 0;
}
.benefit-item p{
  font-size: 17px;

}
.benefits{
  gap: 20px;
}
.main-image.fade-in{
  width: 100% !important;
}
.puf{
  right: 0;
}
.control{
  left: 0;
}
.container{
  margin-top: 15%;
  }
  .unit-label1 p,
  .unit-label p{
    font-size: 10px;
  }

}

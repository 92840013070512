

.MuiBox-root{
    display: flex;
        justify-content: flex-end;

}
.MuiButtonBase-root {
    font-family: "Biome W04 Regular" !important;

}
.MuiBox-root{
    font-family: inherit !important;
}
.footerc{
    font-family: "Biome W04 Regular" !important;

} 
.pagesbox{
    height: 100% !important;
    row-gap: 10px;
}
.appbarh,
.appbarh2{
    height: 40px;
    min-height: 40px !important;
    box-shadow: none !important;
}
.menu-items{
    display: flex;
    flex-direction: row;
}
.MuiPaper-root{
    background-color: #f5ffff !important;
    font-family: "Biome W04 Regular" !important;

}

.MuiSvgIcon-root {
    /* margin-left: 20px; */
   padding-right: 20px;
    padding: 0;

}
/* @media (min-width: 900px) {
    .css-i4bv87-MuiSvgIcon-root{
        display: none !important;
        }  

        
} */

@media (min-width: 0px) and (max-width: 900px) {
    .sdg img.impact-image{
        width: 30% !important;
    }
}
@media (max-width: 900px){
    /* .css-1lfs2tq-MuiTypography-root{
        padding-right: 5%;
    } */
    .MuiBox-root{
        flex-direction: row-reverse;
    }
}
.layout-menu {
    /* margin-right    : 10px; */
}
/* 
@media (min-width: 600px) {
    .css-r6ewbb-MuiToolbar-root {
        min-height: 40px !important ;
    }
} */
/* @media (min-width: 0px) {
    @media (orientation: landscape) {
        .css-r6ewbb-MuiToolbar-root {
            min-height: 40px !important;
        }
    }
} */
/* @media (min-width: 900px) {
    .css-1t6c9ts {
        gap:3%;
        display: flex;
    }
} */
.MuiContainer-root ,
.MuiTypography-root{
    font-family: "Biome W04 Regular" !important;
    /* color: aliceblue; */
    
}
.MuiContainer-root h6,
.MuiTypography-root h6{
color: #1ca0de;    
    
}
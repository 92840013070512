@import "https://unpkg.com/open-props@2.0.0-beta.5" layer(library);
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

@font-face {
  font-family: 'Biome W04 Regular';
  src: url('../fonts/biome-regular.woff2') format('woff2'),
       url('../fonts/biome-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Biome W04 Regular","Biome W04 Regular";
}
.YunitHomeContainerabout {
  position: relative;

}

.overlayabout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Overlay color with transparency */
  
}

.YunitHomeabout {
  width: 100%;
  height: 100%;
  /* object-fit: cover; 
  display: flex; */
  object-fit: contain;
    height: unset;
}
.YunitHomeContainerabout{
  height: 100%;
}
.imageContainerabout{
  height: 100%;
}
.abouttext h1{
  margin: 0;
  font-size: 40px;
  /* font-family: "Dela Gothic One", sans-serif; */
  font-weight: 800;
  font-style: normal;
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255); 
  color: transparent;
  background-image: none; */
  /* background-image:radial-gradient(circle at 12.3% 19.3%, rgb(85, 88, 218) 0%, rgb(95, 209, 249) 100.2%); */
    color: transparent;
    color: #47aad9;
}
.abouttext h2{
  /* color: white; */
  margin: 0;
  text-align:end;
  /* font-family: "Dela Gothic One", sans-serif; */
  /* font-family: "Biome W01 Wide Bold"; */
  font-family: "Biome W04 Regular";
  font-weight: 400;
  font-style: normal;
  font-weight: 10;
  /* -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke: .2px rgb(76, 93, 218); */
  color: aliceblue;
  background-image: none; 
}
.about-us {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 90px ;
  background: #f5ffff;
  justify-content: center;
  padding-top: 0;

}
.members{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  /* justify-content: space-evenly; */

}
.pic {
  height: auto;
  width: 100%;
  max-width: 900px;
  border-radius: 12px;
  object-fit: cover; /* Ensure the image fits within its container */
  margin: 4%;
}

.about {
  /* width: 1130px;
  max-width: 85%; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
}

.text {
  /* width: 540px; */
  max-width: 100%;
  display: contents;
  
}
.design
{
  height: 60%;
  width: 100%;
  background-color: #000;
  position: relative;
  top: 20%;
}
.axlhead{
  
}
.adv{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

}
.text h2 {
  color: #333;
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-top: 3%;
}
.container{
  padding: 2%;
  overflow: hidden;
}
.text h5 {
  color: #333;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}

span {
  color: #4070f4;
}
.spanbold{
  font-weight: 400;
}
.text p {
  color: #333;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 1px;
  text-align: justify;
}

.data {
  margin-top: 30px;
}

.hire {
  font-size: 18px;
  background: #4070f4;
  color: #fff;
  text-decoration: none;
  border: none;
  padding: 12px 25px;
  border-radius: 6px;
  transition: 0.5s;
}

.hire:hover {
  background: #000;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .text h2 {
    font-size: 70px;
  }

  .text h5 {
    font-size: 20px;
  }

  .text p {
    font-size: 16px;
  }

  .pic {
    max-width: 500px;
  }

  .about {
    flex-direction: column;
    align-items: center;
    text-align: center;

  }

  .text {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .text h2 {
    font-size: 50px;
  }

  .text h5 {
    font-size: 18px;
  }

  .text p {
    font-size: 14px;
  }

  .pic {
    max-width: 300px;
  }


  .about-us {
    padding: 0px 50px;
  }

  .hire {
    font-size: 16px;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .text h2 {
    font-size: 40px;
  }

  .text h5 {
    font-size: 16px;
  }

  .text p {
    font-size: 14px;
    line-height: 22px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: justify;
  }
  .about-us {
    padding: 0px 50px;
  }
  .para2{
    display: nonw;
  }

  .pic {
    max-width: 250px;
  }

  .about-us {
    padding: 40px 0;
  }

  .hire {
    font-size: 14px;
    padding: 8px 16px;
  }
}



@layer base {
  html {
    --nav-block-size: 74px;
    --brand-gradient: linear-gradient(227deg, #1400c7 0%, #00bbff 100%);

    @media (prefers-reduced-motion: no-preference) {
      scroll-behavior: smooth;
    }
  }
}

@layer demo {
  .demo-navbar {
    min-block-size: var(--nav-block-size);
    display: grid;
    position: fixed;
    inset-block-start: 0;
    inset-inline: 0;
    background-color: white;
    z-index: var(--layer-3);
    place-items: center end;
    padding-block: 1rem;
    padding-inline: 1.5rem;

    @media (width >=600px) {
      padding-inline: 6dvi;
    }
  }

  .demo-nav-cta-btn {
    font-size: 1rem;
    font-weight: 700;
    inline-size: max-content;
    white-space: nowrap;
    text-decoration: none;
    padding-block: 1rem;
    padding-inline: 1.75rem;
    border: 2px solid transparent;
    color: black;
    position: relative;
    isolation: isolate;

    background: linear-gradient(white, white) padding-box,
      var(--brand-gradient) border-box;
    border-radius: 0.375rem;
    overflow: hidden;
  }

  .demo-nav-cta-btn::before {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    background: var(--brand-gradient);
    mix-blend-mode: screen;
  }

  .demo-nav-cta-btn:hover {
    color: white;
  }

  .demo-nav-cta-btn:hover::before {
    background: var(--brand-gradient) padding-box,
      var(--brand-gradient) border-box;
    background-repeat: no-repeat;
    background-size: contain;
    mix-blend-mode: normal;
    z-index: -1;
  }

  .demo-section {
    --section-block-size: max(400px, 100dvb);
    display: grid;
    background-color: aliceblue;
    min-block-size: var(--section-block-size);
    position: relative;
    block-size: 100%;
    display: grid;
  }

  .demo-section > * {
    grid-area: 1/1;
  }

  .demo-section-wrapper {
    position: relative;
    display: grid;

    @media (width >=960px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (width <960px) {
      padding-block-start: calc(var(--nav-block-size) + 1.75rem);
      padding-block-end: 1.75rem;
    }
  }

  .demo-video {
    display: block;
    inline-size: 100%;
    block-size: 100%;
    object-fit: cover;
    position: relative;
    z-index: -1;
  }

  .demo-content-wrapper {
    display: grid;
    justify-content: space-around;

    @media (width <960px) {
      gap: 1.75rem;
    }
  }

  .demo-meta {
    display: grid;
    gap: 1rem;
  }

  .demo-content {
    display: grid;
    inline-size: 100%;
    place-items: center;
    justify-content: start;
    padding-block: 1.75rem;
    padding-inline: 1.25rem;

    @media (width >=960px) {
      padding: 2.5rem;
      min-block-size: 100cqb;
      place-items: center end;
    }

    @media (width <960px) {
      gap: 1.25rem;
    }
  }

  .demo-mobile-visual {
    inline-size: 100%;
    aspect-ratio: var(--ratio-square);

    @media (width >=960px) {
      display: none;
    }
  }

  .demo-headline {
    font-size: 4rem;
    font-weight: 400;
    max-inline-size: 40rem;
    text-wrap: pretty;
    text-align: center;

    @media (width <960px) {
      font-size: 1.75rem;
    }
  }

  .demo-desc {
    font-size: 1.5rem !important;
    line-height: 1.5;
    max-inline-size: 40ch;
    text-wrap: pretty;

    @media (width <960px) {
      font-size: 1rem;
    }
  }

  .demo-visual {
    display: grid;
    position: sticky;
    block-size: var(--section-block-size);
    inset-block-start: 0;
    container-type: size;

    @media (width <960px) {
      display: none;
    }
  }

  .demo-video-visual {
    inline-size: 100%;
    block-size: var(--section-block-size);
    display: block;
    position: sticky;
    inset-block-start: 0;
    isolation: isolate;
    filter: hue-rotate(210deg);
  }

  .demo-card-wrapper {
    container-type: size;
    display: grid;
    place-items: center;
    overflow: clip;
  }

  .demo-card-wrapper > * {
    grid-area: 1/1;
  }

  .demo-card {
    aspect-ratio: var(--ratio-square);
    inline-size: 70cqi;
    border-radius: 0.375rem;
    scale: 0.4;
  }

  .demo-card-img {
    display: block;
    inline-size: 100%;
    block-size: 100%;
    object-fit: cover;
  }

  .demo-card-1 {
    scale: 1;
  }

  .demo-card-2 {
    translate: -35cqi 30cqb;
    opacity: 0.3;
  }

  .demo-card-3 {
    translate: 0cqi 50cqb;
    opacity: 0.5;
  }

  .demo-card-4 {
    translate: 45cqi 40cqb;
    opacity: 0.5;
  }

  .demo-content-1 {
    --_text-gradient: linear-gradient(227deg, #1993cb 0%, #106790 100%);
  }

  .demo-content-2 {
    --_text-gradient: linear-gradient(227deg, #1993cb 0%, #106790 100%);

    
  }

  .demo-content-3 {
    --_text-gradient: linear-gradient(227deg, #1400c7 0%, #b800b1 100%);
  }

  .demo-content-4 {
    --_text-gradient: linear-gradient(227deg, #b800b1 0%, #f50000 100%);
  }

  .demo-text-highlight {
    background: var(--_text-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

@supports (animation-timeline: scroll()) {
  body {
    timeline-scope: --demo-content-1, --demo-content-2, --demo-content-3, --demo-content-4;
  }

  .demo-section {
    view-timeline-name: --section;
  }

  .demo-content-1 {
    view-timeline-name: --demo-content-1;
  }

  .demo-content-2 {
    view-timeline-name: --demo-content-2;
  }

  .demo-content-3 {
    view-timeline-name: --demo-content-3;
  }

  .demo-content-4 {
    view-timeline-name: --demo-content-4;
  }

  .demo-card {
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  .demo-card-1 {
    animation-timeline: --demo-content-1;
    animation-name: slide-up-first-card;
  }

  .demo-card-2 {
    animation-timeline: --demo-content-2;
    animation-name: slide-up-card;
  }

  .demo-card-3 {
    animation-timeline: --demo-content-3;
    animation-name: slide-up-card;
  }

  .demo-card-4 {
    animation-timeline: --demo-content-4;
    animation-name: slide-up-card;
  }

  .demo-video-visual {
    animation-timeline: --section;
    animation-range-end: exit 110%;
    animation-name: update-hue;
    animation-timing-function: step-end;
    animation-fill-mode: forwards;
  }

  @keyframes update-hue {
    0% {
      filter: hue-rotate(210deg);
    }

    25% {
      filter: hue-rotate(150deg);
    }

    45% {
      filter: hue-rotate(300deg);
    }

    60% {
      filter: hue-rotate(4deg);
    }
  }

  @keyframes slide-up-first-card {
    50% {
      translate: 0;
      opacity: 1;
    }

    90% {
      translate: 0 -50cqi;
      scale: 0.6;
    }

    100% {
      translate: 0 -100cqi;
      opacity: 0;
    }
  }

  @keyframes slide-up-card {
    50% {
      opacity: 1;
      translate: 0;
      scale: 1;
    }

    90% {
      opacity: 0.5;
      scale: 0.6;
      translate: 0 -50cqb;
    }

    100% {
      translate: 0 -100cqi;
      opacity: 0;
    }
  }
}
.profile-card {
  transition: transform 0.3s ease; /* Smooth transition */
}

.profile-card:hover {
  transform: scale(1.05); /* Increase size by 10% */
}










.smallmembers{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap ;
  justify-content: center;
  align-items: center;

}
.axl8text{
  font-size: 20px;
}
.parapdt1{
  /* padding-top: 25%; */
  margin-top: 37%;
}
.axlhead{
  font-size: 40px;
}
.OurBack{
  font-size: 50px;
}

@media (max-width: 1200px) {
  .parapdt1{
    margin-top: 10%;
  }
}
@media (max-width: 1024px) and (min-width: 890px) {
  .parapdt1{
    margin-top: 20%;
  }
}
@media (max-width: 890px) and (min-width: 768px) {
  .parapdt1{
    margin-top: 15%;
  }
}
@media (max-width: 630px) and (min-width: 0px) {
  .parapdt1{
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .smallmembers {
      flex-direction: column; /* Stack cards vertically on small screens */
  }

  .profile-cards {
      max-width: 100%; /* Ensure cards take full width in column layout */
  }
  .aboutmob{
    display: none;
  }
  .product-info h1{
    font-size: 20px;
  }
  .feature-card{
    width: 100%;
  }
  .axlhead{
    font-size: 10px;
    padding: 5%;
  }
  .parapdt1{
    padding-top: 0;
  }
  .axl8text{
    font-size: 15px;
  }
  
  
}
@media (max-width: 450px){
.axl8text{
  font-size: 8px;
}
.OurBack{
  font-size: 30px;
}
}
.profile-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
}

.profile-link:visited {
  color: inherit; /* Ensure color remains the same after visit */
}



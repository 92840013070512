.containernew {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #48a3ed45; */
  position: relative;
}
.product__img img {
  width: 500px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}
.containernew .box {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* max-width: 1400px; */
  height: 100%;
  background: #48a3ed45;
  position: relative;
  animation: openBox 1s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
}

.containernew .box .product__img {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: block;
  opacity: 0;
  animation: fadeImg 1s ease-in-out forwards;
  animation-delay: 1s;
}

.containernew .box .product__video {
  width: 100%;
  max-width: 320px;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
}

.containernew .box .product__video .video {
  display: flex;
  align-items: center;
  opacity: 0;
  animation: upVideo 1s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 1.2s;
}

.containernew .box .product__video .video i {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #89c7e9;
  border: 2px solid #89c7e9;
  border-radius: 50%;
  transition: ease-in-out 220ms;
  cursor: pointer;
}

.containernew .box .product__video .video i:hover {
  background: #89c7e9;
  color: #fff;
  box-shadow: 0 0 26px #89c7e9;
}

.containernew .box .product__video .video p {
  margin-left: 25px;
  font-family: "Biome W04 Regular";
}

.containernew .box .product__disc {
  width: 100%;
  max-width: 500px;
  background: #188ac0;
  margin: 20px auto;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: showDisc 1s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  padding: 20px;
  box-sizing: border-box;
}

.containernew .box .product__disc .product__disc--content {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  animation: fadeContent 1s ease-in-out forwards;
  animation-delay: 1s;
  opacity: 0;
  height: 80%;
}
.additional__info {
  font-family: "Biome W04 Regular";
  color: #f5ffff;
  padding: 15px;
}

.containernew
  .box
  .product__disc
  .product__disc--content
  .disc__content--about {
  width: 100%;
  text-align: center;
}

.containernew
  .box
  .product__disc
  .product__disc--content
  .disc__content--about
  h1 {
  font-size: 2rem;
  font-family: "Biome W04 Regular";
  color: #f5ffff;
}

.containernew
  .box
  .product__disc
  .product__disc--content
  .disc__content--about
  span {
  font-family: "Biome W04 Regular";
  font-size: 1rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  opacity: 0.5;
  color: #f5ffff;
  justify-content: center;
}

.containernew
  .box
  .product__disc
  .product__disc--content
  .disc__content--about
  p {
  color: #f5ffff;
  margin-top: 35px;
  margin-bottom: 35px;
  font-size: 1rem;
  font-family: "Biome W04 Regular";
}

.containernew .box .product__disc .product__disc--content .product__view--more {
  width: 100%;
  display: flex;
  /* align-items: center; */
  position: relative;
  cursor: pointer;
}

.containernew
  .box
  .product__disc
  .product__disc--content
  .product__view--more:hover
  .view__more--block {
  width: 280px;
  height: 35px;
}

.containernew
  .box
  .product__disc
  .product__disc--content
  .product__view--more
  .view__more--block {
  width: 10px;
  height: 15px;
  position: absolute;
  background: #f5ffff;
  z-index: 1;
  transition: cubic-bezier(0.68, -0.55, 0.27, 01.55) 320ms;
}

.containernew
  .box
  .product__disc
  .product__disc--content
  .product__view--more
  p {
  /* font-size: 0.75rem; */
  text-transform: uppercase;
  letter-spacing: 2px;
  display: flex;
  z-index: 2;
  margin-left: 15px;
  color: burlywood;
  font-family: "Biome W04 Regular";
}
/* .product-new-image-container {
  background-color: #0e121b;
} */

.feature-card-featuresov li span{
  color: #15a7eb ;
}
.feature-card-features li span{
  color: #15a7eb ;
}
.containernew .box .product_buttons {
  width: 100%;
  max-width: 500px;
  height: 100px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}

.containernew .box .product_buttons .btn {
  width: 48%;
  height: 70%;
  font-size: 16px;
  font-weight: bold;
  font-family: "Biome W04 Regular";
  transition: ease-in-out 220ms;
  cursor: pointer;
}

.containernew .box .product_buttons .wishlist {
  border: 2px solid #89c7e9;
  background: transparent;
  color: #000000;
  opacity: 0;
  animation: fadeWish 1s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 1s;
}

.containernew .box .product_buttons .wishlist:hover {
  background: #89c7e9;
  color: #fff;
  box-shadow: 0 0 26px #89c7e9;
}

.containernew .box .product_buttons .buy {
  border: none;
  background: #89c7e9;
  color: #000000;
  opacity: 0;
  animation: fadeBuy 1s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 1s;
}

.containernew .box .product_buttons .buy:hover {
  box-shadow: 0 0 26px #89c7e9;
}

@keyframes openBox {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}

@keyframes fadeImg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes upVideo {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes showDisc {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}

@keyframes fadeContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeWish {
  0% {
    opacity: 0;
    bottom: -25px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

@keyframes fadeBuy {
  0% {
    opacity: 0;
    bottom: -25px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

/* Media Queries for responsiveness */
/* @media (max-width: 1200px) {
  .containernew .box .product__disc {
    height: auto;
  }
} */

@media (max-width: 992px) {
  .containernew .box {
    padding: 10px;
  }

  .containernew .box .product__img {
    max-width: 500px;
  }

  .containernew .box .product__video {
    max-width: 280px;
  }

  .containernew .box .product__disc {
    max-width: 450px;
  }

  .containernew .box .product_buttons {
    max-width: 450px;
  }
}

@media (max-width: 768px) {
  .containernew .box .product__disc .product__disc--content {
    padding: 10px;
  }

  .containernew
    .box
    .product__disc
    .product__disc--content
    .disc__content--about
    h1 {
    font-size: 1.5rem;
  }

  .containernew
    .box
    .product__disc
    .product__disc--content
    .disc__content--about
    p {
    font-size: 0.875rem;
  }

  .containernew .box .product__video .video i {
    width: 50px;
    height: 50px;
    font-size: 0.875rem;
  }
  .containernew .box {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .containernew .box .product__img {
    max-width: 90%;
  }

  .containernew .box .product__video .video p {
    font-size: 0.75rem;
    margin-left: 15px;
  }

  .containernew .box .product__disc {
    max-width: 100%;
  }

  .containernew .box .product_buttons .btn {
    width: 48%;
  }
}

.feature-parent hr{
  width: 80%;
}
.containerin {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
}

.containerin h1 {
  font-size: 50px;
  text-align: center;
  color: #000;
  margin-bottom: 20px;
}

.key-features {
  text-align: center;
  background-color: #36728e56;
  padding: 10px;
  border-radius: 8px;
}

.key-features h2 {
  font-size: 16px;
  color: #ffffff;
  background-color: #106790;
  padding: 5px;
  border-radius: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

td {
  padding: 8px;
  border: 1px solid #ddd;
  color: #000;
}

td:first-child {
  text-align: left;
  font-weight: bold;
  background-color: #407994af;
}

td:last-child {
  text-align: right;
}

.feature-parent li {
  margin: 20px;
  font-size: 30px;
  width: 100%;
  list-style-type: none;
}

/* Container needed to position the overlay. Adjust the width as needed */
.product-new-image-container {
  position: relative;
  width: 100%;
}

/* Make the image to responsive */
.product-new-image-container .image {
  width: 100%;
  height: auto;
}
/* .feature-parent{
  background-color: #106790;
} */
/* The overlay effect (full height and width) - lays on top of the container and over the image */
.product-new-image-container .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 99%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgba(0, 0, 0, 0.4);
}

/* When you mouse over the container, fade in the overlay icon*/
.product-new-image-container:hover .overlay {
  opacity: 1;
  border-radius: 10px;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.product-new-image-container .icon {
  color: white;
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

/* When you move the mouse over the icon, change color */
.product-new-image-container .fa-user:hover {
  color: #eee;
}

.product-info {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  /* width: 100%; */
}

.product-info h1 {
  font-size: 50px;
  text-align: center;
  color: #000;
  margin-bottom: 20px;
}

.feature-cards {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.feature-card {
  background-color: #f9f9f900;
  border-radius: 8px;
  border: 2px solid #106790;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  text-align: center;
}
.feature-cardbronze1 {
  background-color: #f9f9f900;
  border-radius: 8px;
  border: 2px solid #106790;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 60%;
  text-align: center;
}
.page1para{
  font-size: 20px;
}

.feature-card-header {
  font-size: 40px;
    font-weight: bolder;
  margin-bottom: 10px;
  color: #000000;
  padding: 10px;
  border-radius: 8px 8px 0 0;
}

.bronze .feature-card-header {
  background-color: #106790;
}

.silver .feature-card-header {
  background-color: #c0c0c0;
}

.gold .feature-card-header {
  background-color: #ffd700;
}

.feature-card-price {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.feature-card-features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.feature-card-features li {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.feature-card-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
}

.feature-card-button:hover {
  background-color: #0056b3;
}









/* Container needed to position the overlay. Adjust the width as needed */
.product-new-image-containerov {
  position: relative;
  width: 100%;
}

/* Make the image responsive */
.product-new-image-containerov .image {
  width: 100%;
  height: auto;
}

/* The overlay effect (full height and width) - lays on top of the container and over the image */
.product-new-image-containerov .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgba(0, 0, 0, 0.7);
}

/* When you mouse over the container, fade in the overlay icon */
.product-new-image-containerov:hover .overlay {
  opacity: 1;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.product-new-image-containerov .icon {
  color: white;
  font-size: 8vw; /* Make the icon size responsive */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

/* When you move the mouse over the icon, change color */
.product-new-image-containerov .fa-user:hover {
  color: #ccc;
}
.names{
  display: flex;
  flex-direction: row;
  gap: 5%;
  padding: 4%;
  padding-bottom: 2%;
  align-items: center;
  justify-content: center;

}
.pilots{
  display: flex;
  padding: 5%;
  flex-wrap: wrap;
  justify-content: center;
padding-bottom: 0;
}
.itemsp{
padding: 2%;
}
.makeinindia-image{
height: 20vw;
}
.makeinindia{
  display: flex;
  align-items: center;
  justify-content: center;
}
.maketext{
 display: flex;
 flex-direction: column;
    justify-content: center;
}
.names h3 {
  font-size: 30px;
  color: #106790;
  width: 50vw;
}

.product-infoov {
  font-family: Arial, sans-serif;
  margin: 5%;
  padding: 5%;
  border-radius: 8px;
  background-color: #00000073;
  color: #eee;
  height: 90%;
}

.product-infoov h1 {
  font-size: 1.5vw; /* Make the font size responsive */
  text-align: center;
  color: #eee;
  margin-bottom: 3%;
}

.feature-cardsov {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: space-around;
  margin-top: 20px;
}

.feature-cardov {
  background-color: #444;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 5%;
  margin: 2%;
  flex: 1 1 30%; /* Flex-grow, flex-shrink, flex-basis for responsive width */
  text-align: center;
  color: #eee;
  min-width: 280px; /* Ensure a minimum width */
}

.feature-card-headerov {
  font-size: 2vw; /* Make the font size responsive */
  margin-bottom: 10px;
  color: #eee;
  background-color: #1067908c;
  padding: 1vw; /* Responsive padding */
  border-radius: 8px 8px 0 0;
}
.h3twonew{
  font-size: 40px;
  color: #106790;
  
}

.bronze .feature-card-headerov {
  background-color: #8b4513;
}

.silver .feature-card-headerov {
  background-color: #c0c0c0;
  color: #333;
}

.gold .feature-card-headerov {
  background-color: #ffd700;
  color: #333;
}

.feature-card-priceov {
  font-size: 2.5vw; /* Make the font size responsive */
  margin-bottom: 20px;
  color: #ddd;
}

.feature-card-featuresov {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.feature-card-featuresov li {
  font-size: 1.4vw; /* Make the font size responsive */
  color: #ddd;
  margin-bottom: 10px;
}

.feature-card-button {
  background-color: #007bff;
  color: #fff;
  padding: 1.5vw 3vw; /* Responsive padding */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
}

.feature-card-button:hover {
  background-color: #0056b3;
}



.classforimg{
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

@media (max-width: 800px) {
.classforimg{
  flex-direction: column;
}
.product-infoov{
  height: 90%;
}
.product-infoov h1{
  font-size: 4vw;
}
.feature-card-featuresov li {
  font-size: 2.5vw;
}
.names .h3two{
  display: none !important;
}
.names .h3twonew{
  display: contents;
}
}
@media (min-width: 800px) {
  .names .h3twonew{
    display: none;
  }
}
.parapdt p{
  font-size: 20px;
  text-align: justify;
  /* padding: 5%; */
  padding-top: 0;
}
.parapdt2 p{
  padding: 5%;
} 
.parapdt h3{
  font-size: 40px;
}
.vertical-line {
  width: 2px; /* Adjust width as needed */
  height: 30vh; /* Adjust height as needed */
  background-color: black; /* Adjust color as needed */
  /* margin: 0 10px;  */
  margin-left: 5%;
    margin-right: 5%;
}
.maketext-container{
  font-size: 30px;
}
.parapdt2 p{
  font-size: 20px;
}
.conth2{
  font-size: 50px;
}
.product-paragraph{
  font-size: 20px;
}
.product-paragraph h3{
  font-size: 30px;
}
.pilothead{
  font-size: 35px;
  color: #106790;
}
.pilotbot{
  font-size: 35px;
  color: #000000;
}
@media (max-width: 650px) {

.feature-cardbronze1 {
  width: 100%;
}
.pilotbot{
  font-size: 20px;
}
}
@media (max-width: 450px) {
  .classforimg{
    align-items: center;
  }
  .feature-card-featuresov li {
    font-size: 2vw;
  }
  .parapdt h3{
    font-size: 20px;
  }
  .maketext-container h3{
    font-size: 15px;
  }
  
  .vertical-line {
    height: 15vh;
  }
  .makeinindia {
    padding: 5%;
  }
  .parapdt p{
    font-size: 12px;
    margin-bottom: 5%;
  }
  .parapdt2 p{
    font-size: 15px;
  }
  .conth2{
    font-size: 30px;
  }
  .page1para{
    font-size: 15px;
  }
  .product-paragraph{
    font-size: 15px;
  }
  .product-paragraph h3{
    font-size: 25px;
  }
  .names h3{
    font-size: 15px;
  }
  .product-info h1{
    font-size: 20px;

  }
  .feature-card-header{
    color: black;
    font-size: 30px;
  }

  .feature-cardbronze1{
    width: 100%;
    }
}
@media (max-width: 350px) {
  .feature-card-featuresov li {
    font-size: 1.5vw;
  }
}
@media (min-width:750px) and (max-width: 850px) {
.product-infoov h1{
  font-size: 1vw;
}
.feature-card-priceov{
  font-size: 1.5vw;
}
.feature-card {
  width: 100%;
}
}

.pilots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px;
}

.itemsp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 220px;

  padding: 15px;

  border-radius: 10px;

  text-align: center;
}

.piloti {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sponsorslogo {
  height: 100px;
  object-fit: contain; /* Ensures the logo is properly scaled */
  margin-bottom: 10px;
}

h4 {
  font-size: 1rem;
  color: #333;
  margin-top: 10px;
}
